.hairdresser-page {
    .detail {
        p {
            font-weight: 900;
            font-size: 16px;

            span {
                font-weight: 400;
                color: var(--text-color);
                text-transform: initial;
            }
        }

        .ant-image {
            img {
                border-radius: 100%;
            }
        }
    }
}

.contact_button {
    color: white;
    background-color: goldenrod;
    border-radius: 10px;
    border: none;
    font-weight: 600;
    width: 100%;
    cursor: pointer;
}

.chat { 
    position: fixed;
    right: 10%;
    bottom: 0;
    width: 30%;
    border-radius: 10px;
    cursor: pointer;
    transition: height 0.5s ease-in-out;
}
  
.fade-in {
    height: 40dvh;
}
  
.fade-out {
    height: 0;
}

.chatMinimize_fade-in {
    height: 5dvh;
}
  
.chatMinimize_fade-out {
    height: 0;
}

.hidden-chat {
    position: fixed;
    right: 10%;
    bottom: 0;
    width: 30%;
    background-color: #5B1310;
    cursor: pointer;
    transition: height 0.5s ease-in-out;
    color: white;
    display: grid;
    place-items: center;
}

.str-chat__theme-light {
    --str-chat__own-message-bubble-background-color: #EBA348;
    --str-chat__own-message-bubble-color: white;
    --str-chat__message-bubble-background-color: #5B1310;
    --str-chat__message-bubble-color: white;
    --str-chat__message-list-background-color: #28020C;
    .str-chat__list {
        --str-chat__message-list-background-color: #28020C;
    }
}

