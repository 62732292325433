.auth-page {
    background-color: var(--secondary-color);
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .auth-content {
        background-color: white;
        width: 500px;
        padding: 40px;
        border-radius: 20px;
    }

    .login-logo {
        width: 40%;
        margin: auto;
        margin-bottom: 30px;
    }
}