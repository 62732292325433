.chat_open_button {
  background-color: #eba348;
  color: white;
  border-radius: 5px;
  border: none;
  width: 100%;
  height: 2.5em;
  font-weight: 600;
  cursor: pointer;
}
.loader_div {
  width: 100%;
  height: 50dvh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bold_chat {
  font-weight: 900;
}

.chat_search_div{
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px 20px;
  justify-content: center;

    .search_chat_field{
      // background-color: rgb(240, 238, 238);
      border: 1px solid rgb(224, 224, 224);
      // border: none;
      border-radius: 25px;
      outline: none;
      padding: 10px 30px;
      width: 100%;
      
    }
  
   .search_button{
      background-color: hsl(33.5deg 75.84% 58.8%);
      color: rgb(91 19 16);
      border-radius: 5px;
      border: none;
      height: 2.5em;
      font-weight: 600;
      cursor: pointer;
      padding: 4px 10px;
   }

}