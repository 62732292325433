.str-chat {
  --str-chat__own-message-bubble-background-color: #eba348;
  --str-chat__own-message-bubble-color: white;
  --str-chat__message-bubble-background-color: #5b1310;
  --str-chat__message-bubble-color: white;
  --str-chat__message-input-background-color: white;
  --str-chat__message-input-color: black;
}

.back_button {
  display: flex;
  place-items: center;
  gap: 0.5em;
  background-color: #5b1310;
  color: white;
  border: none;
  border-radius: 5px;
  text-align: center;
  padding: 1%;
  margin: 0.5% 0;
  font-weight: 600;
  cursor: pointer;
}

.contact_admin_button {
  position: fixed;
  bottom: 10%;
  right: 21%;
  width: 10%;
  height: 4em;
  border-radius: 50px;
  border: none;
  text-align: center;
  background-color: #881711;
  font-weight: 600;
  color: white;
  box-shadow: 0 5px 15px rgba(232, 153, 103, 0.4);
  cursor: pointer;
  transition: 0.3s all ease-in-out;

  &::after {
    content: " \00bb";
    opacity: 0;
    transition: 1s all ease-in-out;
  }
  &:hover {
    transform: scale(1.1);
  }
  &:hover::after {
    opacity: 1;
  }
}
