.home-page {

    .ant-card {
        padding: 10px;

        a {
            color: var(--secondary-color);
            font-weight: 600;
        }
    }

    .ant-card-head-title {
        font-size: 14px;
        text-transform: uppercase;
    }

    .ant-card-body {
        font-size: 30px;
        font-weight: 500;

        h4 {
            font-size: 42px;
            font-weight: 700;
            margin-bottom: auto;
        }
    }
}