@import './components/button';
@import './components/lockscreen';
@import './components/modal';
@import './components/form';
@import './components/drawer';
@import './pages/loginPage';
@import './pages/default-page.scss';
@import './pages/home-page.scss';


@font-face {
    font-family: "Montserrat-Light";
    src: url("./fonts/Montserrat-Light.woff");
}

@font-face {
    font-family: "Montserrat-Medium";
    src: url("./fonts/Montserrat-Medium.ttf");
}

@font-face {
    font-family: "Montserrat-Regular";
    src: url("./fonts/Montserrat-Regular.ttf");
}

@font-face {
    font-family: "FiraSans-Regular";
    src: url("./fonts/FiraSans-Regular.ttf");
}

html {
    --primary-color: #5B1310;
    --secondary-color: #881711;
    --white-color: #FFFFFF;
    --bg-color: rgb(246, 247, 249);
    --yellow-color: #EBA348;
    --text-color: #494949;
}

html,
body {
    height: 100%;
    margin: 0;
}

body {
    padding: 0;
    margin: 0;
    color: #000000cf !important;
    font-family: "FiraSans-Regular";
}


a {
    color: var(--primary-color);
}

a:hover {
    color: var(--primary-color);
}

.card {
    background-color: white;
    // border: 1px solid #0000000d;
    padding: 18px
}

.shadow {
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.20);
}

.align-center {
    text-align: center;
}

// ALERTE
.alert-error {
    margin-bottom: 10px;
    font-weight: 500;

    .ant-alert-message {
        color: #c80000 !important;
    }
}

.ant-result-success {
    .ant-result {
        padding: 30px !important;
    }

    .ant-result-title {
        font-size: 19px;
    }
}

h3 {
    font-weight: 600;
}

.ant-card {
    border-radius: 0.375rem;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 #00000000;

    h3 {
        font-weight: 600;
        color: var(--primary-color);
    }
}

.ant-page-header {
    padding: 5px 0px;
    background-color: transparent;
}

// Modal
.ant-modal-title {
    font-size: 20px;
    text-transform: uppercase;
}

.resume-transaction {
    text-align: center !important;

    .pincode {
        margin: 20px 87px;
    }

    .fees {
        background: var(--primary-color);
        border-radius: 5px;
        color: white;
        font-size: 18px;
        padding: 7px;
        margin: 0px 150px;
    }

    .price {
        font-size: 20px;
        margin-top: 10px;
        margin-bottom: 5px;
    }

    p {
        font-weight: 500;
        color: #000000ad;
        margin: 10px;

        span {
            font-weight: initial;
        }
    }

    .react-codes-input__wrapper___mwAv1 {
        border: none;
    }

    .react-codes-input__code-wrapper___3gxOo {
        font-family: monospace;
        border-radius: 6px;
        border: 1px solid lightgrey;
        // box-shadow: rgb(0 0 0 / 10%) 0px 0px 10px 0px;
        margin: 4px;
        padding-left: 8px;
        width: 36px;
        height: 42px;
        font-size: 32px;
        box-sizing: border-box;
        color: black;
        background-color: white;
    }

    .react-codes-input__code-wrapper___3gxOo :after {
        font-family: monospace;
        border-radius: 6px;
        border: 1px solid lightgrey;
        // box-shadow: rgb(0 0 0 / 10%) 0px 0px 10px 0px;
        margin: 4px;
        padding-left: 8px;
        width: 36px;
        height: 42px;
        font-size: 32px;
        box-sizing: border-box;
        color: black;
        background-color: white;
    }

    .react-codes-input__code___pEiUJ {
        border: none;
    }

    .ant-result-subtitle {
        font-size: 17px;
    }
}

.error-dialog {
    .ant-result {
        padding: 23px 7px;
    }
}

.ant-spin {
    color: var(--primary-color);
}

.ant-card-body {
    .title {
        margin-bottom: 13px;
    }
}

.gallery {
    padding: 10px 0 22px 0;
}

hr {
    background-color: var(--primary-color);
}