.order-page {
    .detail {
        h4 {
            font-weight: 900;
            font-size: 15px;
            color: black;

            span {
                font-weight: initial;
                color: var(--text-color)
            }
        }
    }

    .view-link {
        color: var(--secondary-color);
        cursor: pointer;
    }



    .table {
        width: 100%;
        margin-bottom: 1rem;
        color: #212529;

        thead {
            font-size: 15px;
            font-weight: 600;
            color: var(--secondary-color);
            text-transform: uppercase;
        }

        td,
        th {
            padding: 0.75rem;
            vertical-align: top;
            border: 1px solid #dee2e6;
        }
    }
}

.package-number {
    color: var(--secondary-color) !important;
    cursor: pointer;

}