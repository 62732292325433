.lockscreen {

    text-align: center !important;

    .content {
        padding: 40px;
    }

    h4 {
        font-size: 16px;
        font-weight: 700;
        margin-top: 10px;
    }

    p {
        font-size: 16px;
        color: #000000ad;
        margin: auto;
    }
}