.appointment-page {
    .detail {
        p {
            font-weight: 900;
            font-size: 16px;

            span {
                font-weight: 400;
                color: var(--text-color);
                text-transform: initial;
            }
        }

        .ant-image {
            img {
                border-radius: 100%;
            }
        }
    }
}