.Chat_container {
  box-shadow: 1px 1px 10px 1px grey;
  border: 1px solid lightgray;
  background-color: white;
  z-index: 1000000;
}

.bottom_container {
  position: fixed;
  bottom: 0;
  right: 20%;
  height: 40dvh;
  width: 23%;
}

.normal_container {
  width: 70em;
  height: 70dvh;
  box-shadow: none !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.normal_container {
  .time_container {
    width: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      margin: 0;
      font-size: smaller;
      font-weight: 500;
      color: gray;
    }
  }
}

.Header {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #5b1310;
  padding: 2rem 0;
  height: 12%;
  position: relative;
}

.Header > h3,
.Header > span {
  margin: 0;
  color: white;
  text-align: center;
}

.Header > span {
  cursor: pointer;
  font-weight: bold;
  font-size: 18px;
  position: absolute;
  right: 2%;
  top: 5%;
}

.Chat_body {
  height: 70%;
  overflow-y: scroll;
  scroll-behavior: smooth;
  margin-bottom: 0.25rem;
}

.Right_Message {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 2%;
}

.Left_Message {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2%;
}

.Right_Message > .message {
  background-color: #eba348;
  padding: 1rem;
  color: white;
  border-radius: 20px;
  font-weight: 600;
}

.Left_Message > .message {
  padding: 1rem;
  color: white;
  background-color: #5b1310;
  border-radius: 20px;
  font-weight: 600;
}

.message > p {
  margin: 0;
}

.Input_Container {
  display: flex;
  width: 100%;
  height: 10%;
  padding: 0 1rem;
  align-items: center;
  border-top: 1px solid lightgray;
  background-color: white;
}

.Input_Container > button {
  width: 25%;
  border-radius: 10px !important;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Input_Container > input {
  border: none;
  padding: 1%;
  margin-right: 1%;
}

.Input_Container > input:focus {
  border: none !important;
  box-shadow: none !important;
}
