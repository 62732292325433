.ant-btn-primary {
    color: #fff;
    border-radius: 5px;
    border-color: var(--primary-color);
    background-color: var(--primary-color);
}

.ant-btn-secondary {
    color: #fff;
    border-radius: 5px;
    border-color: var(--secondary-color);
    background-color: var(--secondary-color);
}

.ant-btn-default {
    border-radius: 5px;
}

.btn {
    height: 42px;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.8rem 1rem;
    font-size: 0.8125rem;
    line-height: 1.5;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    text-transform: uppercase;
    border: none;
    border-radius: 5px;
    transition: color .4s, background-color .4s, border-color .4s, box-shadow .4s
}

.btn-lg {
    width: -webkit-fill-available;
}

.btn-sm {
    padding: 0.25rem 0.5rem !important;
    font-size: 0.710938rem;
    line-height: 1.5;
}

.btn-primary {
    background-color: var(--primary-color);
    color: #fff !important;
    padding: 0.6rem .75rem;
}

.btn-primary:hover {
    color: #fff !important;
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}

.btn-primary.focus,
.btn-primary:focus {
    color: #fff;
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    box-shadow: 0 0 0 .2rem rgba(105, 136, 228, .5)
}

.btn-secondary:focus,
.btn-secondary:active {
    border-color: var(--secondary-color);
    background-color: var(--secondary-color);
    color: #fff;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
    color: #fff;
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}



.btn-secondary {
    border-color: var(--secondary-color);
    background-color: var(--secondary-color);
    color: #fff;
    border-radius: 5px;
    font-weight: 500;
}

.btn-secondary:hover {
    border-color: var(--secondary-color);
    background-color: var(--secondary-color);
    color: #fff;
}