.home-page {

    .ant-card {
        p {
            font-size: 21px;
            font-weight: 700;
            margin-bottom: 0px;
            margin-top: 12px;
        }
    }
}